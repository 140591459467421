function generate_weather_charts(weather) {
    var cume = {
        daily_precip_in: [],
        mrms_precip_in: [],
        c: 0
    };

    cume.c = 0;
    for (i = 0; i < weather.data.length; i++) {
        cume.c += weather.data[i].daily_precip_in;
        cume.daily_precip_in.push(cume.c);
    }

    cume.c = 0;
    for (i = 0; i < weather.data.length; i++) {
        cume.c += weather.data[i].mrms_precip_in;
        cume.mrms_precip_in.push(cume.c);
    }

    var values = {};
    values.daily_low_f = _.map(weather.data, 'daily_low_f');
    values.daily_high_f = _.map(weather.data, 'daily_high_f');
    values.climate_daily_low_f = _.map(weather.data, 'climate_daily_low_f');
    values.climate_daily_high_f = _.map(weather.data, 'climate_daily_high_f');
    values.mrms_precip_in = _.map(weather.data, 'mrms_precip_in');
    values.daily_precip_in = _.map(weather.data, 'daily_precip_in');
    values.climate_daily_precip_in = _.map(weather.data, 'climate_daily_precip_in');
    values.cume = cume;
    var labels = _.map(weather.data, 'date');




    var ctx = document.getElementById("chart_daily_precip_in");
    var data = {
        labels: labels,
        datasets: [
            {
                label: "Daily Precipitation (inches)",
                yAxisID: "y-axis-0",
                data: values.daily_precip_in,
                spanGaps: true,
                backgroundColor: "#002f87",
            },
            {
                type: 'line',
                label: "Cumulative Precipitation (inches)",
                data: values.cume.daily_precip_in,
                // lineTension: 5,
                pointBorderWidth: 0,
                pointRadius: 0,
                pointHoverBorderWidth: 1,
                pointHoverRadius: 5,
                yAxisID: "y-axis-1",
                spanGaps: false,
                backgroundColor: "rgba(29, 111, 203, 0.2)",
                borderWidth: 1,
                borderColor: "#002f87"
            },
        ]
    };
    var chart_daily_precip_in = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: {
            legend: {
                usePointStyle: true,
                pointStyle: "circle"
            },
            scales: {
                yAxes: [{
                    position: "left",
                    "id": "y-axis-0",
                    fixedStepSize: 0.25
                }, {
                    position: "right",
                    "id": "y-axis-1",
                    fixedStepSize: 0.25
                }],
                xAxes: [{
                    position: 'bottom',
                    ticks: {
                        autoSkipPadding: 20
                    }
                }]
            }
        }
    });


    // ctx = document.getElementById("chart_mrms_precip_in");
    // data = {
    //     labels: labels,
    //     datasets: [
    //         {
    //             label: "Daily Precipitation",
    //             yAxisID: "y-axis-0",
    //             data: values.mrms_precip_in,
    //             spanGaps: false,
    //             backgroundColor: "#002f87",
    //             barPercentage: 0.5
    //         },
    //         {
    //             type: 'line',
    //             label: "Cumulative Precipitation",
    //             data: values.cume.mrms_precip_in,
    //             lineTension: 0.1,
    //             pointBorderWidth: 0,
    //             pointRadius: 0,
    //             pointHoverBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             yAxisID: "y-axis-1",
    //             spanGaps: false,
    //             backgroundColor: "rgba(29, 111, 203, 0.2)",
    //             borderWidth: 1,
    //             borderColor: "#002f87"
    //         },
    //
    //     ]
    // };
    // var chart_mrms_precip_in = new Chart(ctx, {
    //     type: 'bar',
    //     data: data,
    //     options: {
    //         scales: {
    //             yAxes: [{
    //                 position: "left",
    //                 "id": "y-axis-0",
    //                 fixedStepSize: 0.25
    //             }, {
    //                 position: "right",
    //                 "id": "y-axis-1",
    //                 fixedStepSize: 0.25
    //             }],
    //             xAxes: [{
    //                 position: 'bottom',
    //                 ticks: {
    //                     autoSkipPadding: 20
    //                 }
    //             }]
    //         }
    //     }
    // });


    ctx = document.getElementById("chart_temp");
    data = {
        labels: labels,
        datasets: [
            {
                label: "Daily High",
                yAxisID: "y-axis-0",
                data: values.daily_high_f,
                fill: false,
                lineTension: 0.1,
                pointBorderWidth: 0,
                pointRadius: 0,
                pointHoverBorderWidth: 0.5,
                pointHoverRadius: 5,
                spanGaps: false,
                borderWidth: 1,
                borderColor: "#67001f"
            },
            {
                label: "Daily low",
                yAxisID: "y-axis-0",
                data: values.daily_low_f,
                fill: false,
                lineTension: 0.1,
                pointBorderWidth: 0,
                pointRadius: 0,
                pointHoverBorderWidth: 0.5,
                pointHoverRadius: 5,
                spanGaps: false,
                borderWidth: 1,
                borderColor: "#002f87"
            },
            {
                type: 'line',
                label: "Climate High",
                data: values.climate_daily_high_f,
                fill: false,
                lineTension: 0,
                pointBorderWidth: 0,
                pointRadius: 0,
                pointHoverBorderWidth: 0.5,
                pointHoverRadius: 5,
                yAxisID: "y-axis-0",
                spanGaps: false,
                borderWidth: 1,
                borderColor: "#d6604d"
            },
            {
                type: 'line',
                label: "Climate Low",
                data: values.climate_daily_low_f,
                fill: false,
                lineTension: 0,
                pointBorderWidth: 0,
                pointRadius: 0,
                pointHoverBorderWidth: 0.5,
                pointHoverRadius: 5,
                yAxisID: "y-axis-0",
                spanGaps: false,
                borderWidth: 1,
                borderColor: "#1d6fcb"
            },

        ]
    };
    var chart_temp = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
            scales: {
                yAxes: [{
                    position: "left",
                    "id": "y-axis-0",
                }],
                xAxes: [{
                    position: 'bottom',
                    ticks: {
                        autoSkipPadding: 20
                    }
                }]
            }
        }
    });
}